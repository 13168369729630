import image1 from '../assets/images/image1.jpg';
import image2 from '../assets/images/image2.jpg';
import image3 from '../assets/images/image3.jpg';
import image6 from '../assets/images/image6.jpg';

import anouchka from '../assets/images/anouchka.jpg';
import kasic from '../assets/images/kasic.jpg';


export const projects = [
  {
    id: 1,
    title: "Programme de Distribution de Matériel",
    description: "Collecte et redistribution de matériel essentiel (ordinateurs, fournitures scolaires, vêtements) aux populations vulnérables et organisations communautaires au Gabon.",
    image: image1,
    status: "en_cours",
    category: "Distribution",
    details: {
      objectifs: "Le projet vise à collecter du matériel divers (ordinateurs, fournitures scolaires, vêtements, équipements médicaux, etc.) pour le redistribuer à des communautés vulnérables, en particulier dans les régions rurales du Gabon et d’Afrique.",
      impacts: [
        "Éducation améliorée : accès à des ressources pédagogiques pour les étudiants.",
        "Autonomie des communautés : soutien aux projets locaux.",
        "Réduction de la fracture numérique : opportunités de formation à distance."
      ],
      contribution: [
        "Faire un don matériel : ordinateurs, livres, vêtements, etc.",
        "Faire un don financier pour soutenir la logistique.",
        "Devenir bénévole pour organiser et trier les collectes."
      ]
    }
  },
  {
    id: 2,
    title: "Soutien au Développement Local et à l'Entrepreneuriat",
    description: "Fourniture d'outils et de ressources aux entrepreneurs locaux et aux projets communautaires pour l'éducation, la santé et l'agriculture.",
    image: image2,
    status: "en_cours",
    category: "Entrepreneuriat",
    details: {
      objectifs: "Accompagner les entrepreneurs locaux, les agriculteurs et les organisations communautaires en leur fournissant les outils nécessaires pour se développer durablement.",
      impacts: [
        "Création d’emplois locaux : dynamisation économique.",
        "Renforcement de la capacité entrepreneuriale.",
        "Autonomie alimentaire et économique."
      ],
      contribution: [
        "Faire un don matériel spécialisé pour l’agriculture et l’artisanat.",
        "Contribuer financièrement pour soutenir les projets locaux.",
        "Devenir partenaire pour financer ou fournir du matériel."
      ]
    }
  },
  {
    id: 3,
    title: "Ateliers de Développement des Compétences",
    description: "Organisation de sessions de formation pour renforcer les compétences techniques et entrepreneuriales des bénéficiaires pour une croissance durable.",
    image: image3,
    status: "en_cours",
    category: "Formation",
    details: {
      objectifs: "Organiser des formations pour développer les compétences techniques et entrepreneuriales dans divers secteurs comme l’informatique, l’agriculture durable, et la gestion d’entreprises.",
      impacts: [
        "Amélioration des compétences professionnelles.",
        "Réduction du chômage : nouvelles opportunités d’emploi.",
        "Autonomisation des jeunes et des femmes."
      ],
      contribution: [
        "Faire un don financier pour financer les formations.",
        "Offrir des formations si vous avez une expertise spécifique.",
        "Soutenir l’organisation logistique des ateliers."
      ]
    }
  },
  {
    id: 4,
    title: "Initiative d'Économie Circulaire",
    description: "Recyclage des matériaux inutilisés pour réduire les déchets et soutenir les projets de développement durable.",
    image: "https://images.unsplash.com/photo-1532996122724-e3c354a0b15b",
    status: "à_venir",
    category: "Environnement",
    details: {
      objectifs: "Promouvoir l’économie circulaire en réutilisant des biens matériels inutilisés ou en surplus pour soutenir des initiatives locales.",
      impacts: [
        "Réduction des déchets : impact environnemental limité.",
        "Amélioration des conditions de vie grâce à la redistribution.",
        "Sensibilisation à l’importance de la réutilisation des ressources."
      ],
      contribution: [
        "Faire un don de matériel inutilisé : vêtements, meubles, outils.",
        "Contribuer financièrement pour financer la logistique.",
        "Promouvoir l’économie circulaire auprès de votre réseau."
      ]
    }
  },
  {
    id: 5,
    title: "Programme d'Aide Humanitaire",
    description: "Fourniture de matériel et d'équipements essentiels aux communautés lors des situations d'urgence ou de crise.",
    image: "https://images.unsplash.com/photo-1469571486292-0ba58a3f068b",
    status: "en_cours",
    category: "Urgence",
    details: {
      objectifs: "Fournir une aide humanitaire rapide en cas de crise ou de catastrophe naturelle pour aider les populations sinistrées.",
      impacts: [
        "Réponse rapide aux crises pour minimiser les impacts.",
        "Soulagement des populations sinistrées : retour à la normale.",
        "Préparation aux futures catastrophes grâce à des infrastructures adaptées."
      ],
      contribution: [
        "Faire un don matériel : médicaments, tentes, outils de secours.",
        "Contribuer financièrement pour financer les interventions.",
        "Participer à des missions de terrain en cas de besoin."
      ]
    }
    },
  {
  id: 6,
  title: "Création d'un Réseau de Partenariats",
  image: image6,
  description: "Établir des partenariats solides avec des entreprises et des organisations.",
  status: "en_cours",
  category: "Partenariat",
  details: {
    objectifs: [
      "Renforcer les collaborations avec des acteurs locaux et internationaux.",
      "Faciliter l'accès à des financements et des ressources partagées.",
      "Accroître l'impact de nos initiatives par des actions coordonnées."
    ],
    impacts: "Le réseau permettra d'augmenter la portée et l'efficacité de nos projets, en s'appuyant sur l'expertise et les ressources de nos partenaires.",
    contribution: [
      "Participer aux discussions pour renforcer les partenariats.",
      "Apporter une expertise ou des idées pour améliorer les initiatives.",
      "Promouvoir les partenariats auprès de nouvelles entreprises ou organisations."
    ]
  }
},
{
  id: 7,
  title: "Logistique et Suivi des Dons",
  image: "https://images.unsplash.com/photo-1558959357-685f9c7ace7b",
  description: "Assurer une gestion efficace et un suivi transparent des matériels collectés.",
  status: "à_venir",
  category: "Logistique",
  details: {
    objectifs: [
      "Optimiser les processus de collecte et de distribution des dons.",
      "Garantir la transparence dans le suivi des dons matériels.",
      "Éviter les pertes ou les erreurs logistiques grâce à une meilleure organisation."
    ],
    impacts: "Une logistique améliorée permettra une distribution plus rapide et équitable des dons, augmentant la satisfaction des donateurs et des bénéficiaires.",
    contribution: [
      "Aider au tri et à l'organisation des dons dans les entrepôts.",
      "S'assurer de la qualité des matériaux avant leur distribution.",
      "Participer à la livraison des dons aux bénéficiaires.",
      "Partager des retours d'expérience pour améliorer le processus."
    ]
  }
}
] as const;

export const team = [
  {
    id: 1,
    name: "Anouchka MINKOUE OBAME",
    role: "Présidente et Secrétaire Générale",
    bio: "Membre fondatrice d'AFRIKLUMI, engagée dans le développement durable et l'aide humanitaire en Afrique.",
    image: anouchka
  },
  {
    id: 2,
    name: "Kasic HAUCHARD",
    role: "Trésorier",
    bio: "Membre fondateur d'AFRIKLUMI, dévoué à la gestion et au développement des projets humanitaires.",
    image: kasic
  }
] as const;
