import { Project } from '../types';
import { useNavigate } from 'react-router-dom';
import { IMAGE_SIZES } from '../utils/imageUtils';

interface ProjectCardProps {
  project: Project;
}

export default function ProjectCard({ project }: ProjectCardProps) {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105">
      <div className="relative">
        <img
          className="h-48 w-full object-cover"
          src={project.image}
          alt={project.title}
          style={{
            aspectRatio: IMAGE_SIZES.projectCard.aspectRatio,
            width: '100%',
            height: 'auto'
          }}
          loading="lazy"
        />
        <span className="absolute top-4 right-4 px-3 py-1 rounded-full text-sm bg-white/90 text-gray-800">
          {project.category}
        </span>
      </div>
      <div className="p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">{project.title}</h3>
        <p className="text-gray-600 mb-4">{project.description}</p>
        <button 
          onClick={() => navigate(`/projects/${project.id}`)}
          className="text-green-600 hover:text-green-700 font-medium flex items-center"
        >
          En savoir plus 
          <span className="ml-2">→</span>
        </button>
      </div>
    </div>
  );
}