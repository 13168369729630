import React from 'react';

export default function WelcomeSection() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">
          Bienvenue chez AfrikLumi, ensemble pour un avenir solidaire et durable !
        </h2>
        
        <div className="prose prose-lg mx-auto text-gray-600 space-y-6">
          <p className="text-justify">
            En Afrique, et particulièrement au Gabon, de nombreuses petites entreprises, écoles et structures locales manquent de ressources essentielles pour se développer pleinement. AfrikLumi est née d'une conviction simple : chaque objet a une seconde vie à offrir.
          </p>
          
          <p className="text-justify">
            En France, tant de biens en bon état – matériel bureautique, informatique, 
            électroménager et agricole – sont souvent jetés ou sous-utilisés. Notre mission 
            est de collecter ces équipements pour les redistribuer au Gabon à des prix 
            accessibles. L'objectif ? Soutenir les petites entreprises, les écoles et les 
            organisations locales en leur fournissant des ressources de qualité à moindre coût.
          </p>
          
          <p className="text-justify">
            Ensemble, donnons un nouvel élan aux projets de développement et bâtissons 
            une communauté plus épanouie et solidaire.
          </p>
          
          <p className="text-center font-semibold text-green-600">
            Chaque don compte. Chaque geste fait la différence.
          </p>
        </div>
      </div>
    </section>
  );
}