export const IMAGE_SIZES = {
  projectCard: {
    width: 800,
    height: 600,
    aspectRatio: '4:3'
  },
  projectDetail: {
    width: 1200,
    height: 800,
    aspectRatio: '3:2'
  },
  hero: {
    width: 1920,
    height: 1080,
    aspectRatio: '16:9'
  }
} as const;

export function getImageUrl(imagePath: string, size: keyof typeof IMAGE_SIZES): string {
  // Pour l'instant, retourne simplement l'URL de l'image
  // Dans le futur, on pourrait ajouter des paramètres de redimensionnement
  return imagePath;
}

export function validateImageDimensions(
  file: File,
  size: keyof typeof IMAGE_SIZES
): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      const { width, height } = IMAGE_SIZES[size];
      resolve(img.width >= width && img.height >= height);
    };
    img.src = URL.createObjectURL(file);
  });
}