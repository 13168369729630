import React, { useState } from 'react';
import { Gift, CreditCard, Package, Truck } from 'lucide-react';

export default function DonatePage() {
  const [donationType, setDonationType] = useState<'material' | 'financial' | null>(null);

  return (
    <div className="pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Faire un Don</h1>
          <p className="text-xl text-gray-600">
            Votre soutien est essentiel pour nos projets humanitaires et le développement durable en Afrique
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8 mb-16">
          <button
            onClick={() => setDonationType('material')}
            className={`p-8 rounded-lg shadow-lg text-left transition-all ${
              donationType === 'material' 
                ? 'bg-green-50 border-2 border-green-600' 
                : 'bg-white hover:bg-gray-50'
            }`}
          >
            <Package className="w-12 h-12 text-green-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Don Matériel</h3>
            <p className="text-gray-600">
              Donnez du matériel agricole, agroalimentaire, informatique ou tout autre équipement
              utile pour nos projets de développement.
            </p>
          </button>

          <button
            onClick={() => setDonationType('financial')}
            className={`p-8 rounded-lg shadow-lg text-left transition-all ${
              donationType === 'financial' 
                ? 'bg-green-50 border-2 border-green-600' 
                : 'bg-white hover:bg-gray-50'
            }`}
          >
            <CreditCard className="w-12 h-12 text-green-600 mb-4" />
            <h3 className="text-xl font-semibold mb-2">Don Financier</h3>
            <p className="text-gray-600">
              Soutenez nos actions par un don financier pour la logistique, les expéditions
              et la gestion de nos projets.
            </p>
          </button>
        </div>

        {donationType === 'material' && (
          <div className="bg-white rounded-lg shadow-lg p-8 mb-16">
            <h2 className="text-2xl font-bold mb-6">Faire un don matériel</h2>
            <div className="space-y-6">
              <div>
                <h3 className="text-xl font-semibold mb-4">Types de matériel accepté :</h3>
                <div className="grid md:grid-cols-2 gap-6">
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Matériel agricole et de transformation alimentaire</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Machines pour la transformation des produits agricoles (presse pour huile, moulins à farine, séchoirs).</li>
                      <li>Équipements pour l'agriculture durable (pompes solaires, serres, outillage agricole manuel ou motorisé).</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Matériel de construction et d'artisanat</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Outils de construction (mélangeurs de béton, outils de menuiserie, équipements pour le travail du bois).</li>
                      <li>Matériaux écologiques pour des projets de construction durable.</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Équipements éducatifs et technologiques</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Matériel informatique (ordinateurs, imprimantes 3D pour formations techniques, tablettes éducatives).</li>
                      <li>Matériel pour centres de formation (vidéoprojecteurs, tableaux interactifs, kits de robotique).</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Matériel pour la santé et le bien-être</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Équipements médicaux de base (tensiomètres, thermomètres, lits d'hôpital, fauteuils roulants).</li>
                      <li>Kits pour l'hygiène (savons, purificateurs d'eau, lampes solaires pour zones rurales).</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Équipements pour la restauration et l'hôtellerie</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Ustensiles et machines de cuisine (friteuses industrielles, fours, réfrigérateurs solaires ou pas, congelateurs, chambres froides).</li>
                      <li>Mobilier fonctionnel et robuste pour cafés ou restaurants.</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Énergies renouvelables</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Panneaux solaires, batteries de stockage, kits d'éclairage autonome.</li>
                    </ul>
                  </div>
                  <div>
                    <h4 className="font-semibold text-green-600 mb-2">Transport</h4>
                    <ul className="list-disc pl-6 text-gray-600 space-y-2">
                      <li>Petits véhicules utilitaires, tricycles motorisés pour le transport de marchandises, vélos.</li>
                    </ul>
                  </div>
                </div>
              </div>
              
              <div className="bg-green-50 p-6 rounded-lg mt-8">
                <div className="flex items-center gap-3 mb-4">
                  <Truck className="w-6 h-6 text-green-600" />
                  <h3 className="text-xl font-semibold">Service de collecte disponible</h3>
                </div>
                <p className="text-gray-600 mb-4">
                  Nous pouvons nous déplacer pour récupérer vos dons. Contactez-nous pour organiser
                  la collecte à l'adresse qui vous convient. Nous stockons dans la limite de notre espace. 
                  Nous pouvons également acheter certains matériels dans la limite de nos moyens.
                </p>
                <div className="bg-white p-4 rounded-lg">
                  <p className="font-semibold">AFRIKLUMI</p>
                  <p>319 Route de la Chapelle</p>
                  <p>76640 Cléville, France</p>
                  <p>Tél : 06 58 89 85 31</p>
                  <p>Email : afriklumi01@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {donationType === 'financial' && (
          <div className="bg-white rounded-lg shadow-lg p-8 mb-16">
            <h2 className="text-2xl font-bold mb-6">Faire un don financier</h2>
            <div className="space-y-6">
              <div className="bg-green-50 p-6 rounded-lg mb-8">
                <h3 className="text-xl font-semibold mb-4">Utilisation de vos dons</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-600">
                  <li>Frais de logistique et d'expédition du matériel</li>
                  <li>Gestion et coordination des projets sur le terrain</li>
                  <li>Maintenance et entretien des équipements</li>
                  <li>Formation et accompagnement des bénéficiaires</li>
                </ul>
              </div>

              <div className="text-center">
                <a 
                  href="https://www.helloasso.com/associations/afriklumi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-green-600 text-white px-8 py-4 rounded-lg hover:bg-green-700 transition-colors text-lg font-semibold"
                >
                  Faire un don sur HelloAsso
                </a>
                <p className="mt-4 text-gray-600">
                  Vous serez redirigé vers notre page HelloAsso pour effectuer votre don en toute sécurité.
                </p>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg mt-8">
                <h3 className="text-xl font-semibold mb-4">Avantages fiscaux</h3>
                <p className="text-gray-600">
                  Les dons à AFRIKLUMI sont déductibles des impôts à hauteur de 66% 
                  dans la limite de 20% du revenu imposable. Un reçu fiscal vous sera 
                  envoyé pour votre déclaration d'impôts.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}