import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/content';
import { ArrowLeft } from 'lucide-react';

export default function ProjectDetailsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const project = projects.find((p) => p.id === Number(id));

  if (!project) {
    return (
      <div className="pt-24 px-4 text-center">
        <h2 className="text-2xl font-bold text-gray-900">Projet non trouvé</h2>
        <button
          onClick={() => navigate('/')}
          className="mt-4 inline-flex items-center text-green-600 hover:text-green-700"
        >
          <ArrowLeft className="mr-2" size={20} />
          Retour à l'accueil
        </button>
      </div>
    );
  }

  return (
    <div className="pt-24 pb-16">
      <div className="max-w-4xl mx-auto px-4">
        <button
          onClick={() => navigate('/')}
          className="mb-8 inline-flex items-center text-green-600 hover:text-green-700"
        >
          <ArrowLeft className="mr-2" size={20} />
          Retour aux projets
        </button>

        <img
          src={project.image}
          alt={project.title}
          className="w-full h-96 object-cover rounded-xl mb-8"
        />
        <h1 className="text-4xl font-bold text-gray-900 mb-6">{project.title}</h1>

        <div className="prose prose-lg max-w-none">
          <p className="text-gray-600 mb-6">{project.description}</p>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Objectifs du projet</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            {project.details?.objectifs
              ? <li>{project.details.objectifs}</li>
              : <li>Objectifs non spécifiés pour ce projet.</li>}
          </ul>

          <h2 className="text-2xl font-bold text-gray-900 mt-8 mb-4">Impact attendu</h2>
          {project.details?.impacts ? (
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              {project.details.impacts.map((impact, index) => (
                <li key={index}>{impact}</li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">Impact non spécifié pour ce projet.</p>
          )}

          <div className="bg-gray-50 p-6 rounded-lg mt-8">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Comment contribuer ?</h2>
            <p className="text-gray-600 mb-4">
              Vous pouvez soutenir ce projet de plusieurs manières :
            </p>
            {project.details?.contribution ? (
              <ul className="list-disc pl-6 text-gray-600 space-y-2">
                {project.details.contribution.map((contribution, index) => (
                  <li key={index}>{contribution}</li>
                ))}
              </ul>
            ) : (
              <p className="text-gray-600">Options de contribution non spécifiées.</p>
            )}
            <button 
              onClick={() => navigate('/donate')}
              className="mt-6 bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 transition-colors"
            >
              Faire un don
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}