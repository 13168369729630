import { Menu, X } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-white shadow-lg fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-green-600 font-montserrat tracking-wider">AFRIKLUMI</Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/" className="text-gray-700 hover:text-green-600">Accueil</Link>
            <Link to="/about" className="text-gray-700 hover:text-green-600">À propos</Link>
            <Link to="/#projects" className="text-gray-700 hover:text-green-600">Nos actions</Link>
            <Link to="/#contact" className="text-gray-700 hover:text-green-600">Contact</Link>
            <Link to="/donate" className="bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700">
              Faire un don
            </Link>
          </div>

          <div className="md:hidden flex items-center">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700">
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <Link to="/" className="block px-3 py-2 text-gray-700 hover:text-green-600">Accueil</Link>
              <Link to="/about" className="block px-3 py-2 text-gray-700 hover:text-green-600">À propos</Link>
              <Link to="/#projects" className="block px-3 py-2 text-gray-700 hover:text-green-600">Nos actions</Link>
              <Link to="/#contact" className="block px-3 py-2 text-gray-700 hover:text-green-600">Contact</Link>
              <Link to="/donate" className="block px-3 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
                Faire un don
              </Link>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}