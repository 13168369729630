import React from 'react';
import { Building, Heart, Users } from 'lucide-react';

export default function AboutPage() {
  return (
    <div className="pt-24 pb-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">À Propos d'AFRIKLUMI</h1>
          <p className="text-xl text-gray-600">Ensemble pour un avenir durable en Afrique</p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 mb-16">
          <div className="text-center p-6 bg-white rounded-lg shadow-lg">
            <Building className="w-12 h-12 text-green-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Notre Mission</h3>
            <p className="text-gray-600">
              Contribuer au développement durable en Afrique à travers des projets d'éducation,
              d'énergie solaire et de développement communautaire.
            </p>
          </div>

          <div className="text-center p-6 bg-white rounded-lg shadow-lg">
            <Heart className="w-12 h-12 text-green-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Nos Valeurs</h3>
            <p className="text-gray-600">
              Solidarité, transparence, durabilité et engagement communautaire sont au cœur
              de nos actions quotidiennes.
            </p>
          </div>

          <div className="text-center p-6 bg-white rounded-lg shadow-lg">
            <Users className="w-12 h-12 text-green-600 mx-auto mb-4" />
            <h3 className="text-xl font-semibold mb-2">Notre Impact</h3>
            <p className="text-gray-600">
              Des projets concrets qui transforment positivement la vie des communautés
              locales en Afrique.
            </p>
          </div>
        </div>

        <div className="prose max-w-none mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Notre Histoire</h2>
          <p className="text-lg text-gray-600 mb-6">
            AFRIKLUMI est née de la volonté de créer un pont entre la France et l'Afrique,
            en particulier le Gabon. Notre association s'engage à collecter et redistribuer
            du matériel essentiel aux populations vulnérables, tout en soutenant des projets
            de développement durable.
          </p>
          <p className="text-lg text-gray-600">
            Fondée par des personnes passionnées par l'aide humanitaire et le développement
            durable, AFRIKLUMI travaille en étroite collaboration avec les communautés locales
            pour identifier leurs besoins et mettre en œuvre des solutions adaptées.
          </p>
        </div>

        <div className="bg-gray-50 rounded-lg p-8 mb-16">
          <h2 className="text-3xl font-bold text-center mb-8">Notre Équipe</h2>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Anouchka MINKOUE OBAME</h3>
              <p className="text-green-600 mb-4">Présidente et Secrétaire Générale</p>
              <p className="text-gray-600">
                Membre fondatrice d'AFRIKLUMI, engagée dans le développement durable
                et l'aide humanitaire en Afrique.
              </p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold mb-2">Kasic HAUCHARD</h3>
              <p className="text-green-600 mb-4">Trésorier</p>
              <p className="text-gray-600">
                Membre fondateur d'AFRIKLUMI, dévoué à la gestion et au développement
                des projets humanitaires.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}