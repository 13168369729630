import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import image0 from '../assets/images/image0.png';

export default function Hero() {
  return (
    <div id="home" className="pt-16 bg-gradient-to-br from-green-50 to-blue-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <div>
            <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl">
              Ensemble pour un avenir
              <span className="text-green-600"> durable</span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg">
              AFRIKLUMI s'engage à créer un impact positif en Afrique à travers des projets
              durables d'éducation, d'énergie solaire et de développement communautaire.
            </p>
            <div className="mt-8 flex gap-4">
              <Link 
                to="/donate" 
                className="bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 flex items-center gap-2"
              >
                Faire un don
                <ArrowRight size={20} />
              </Link>
              <Link 
                to="/about"
                className="border-2 border-green-600 text-green-600 px-6 py-3 rounded-md hover:bg-green-50"
              >
                En savoir plus
              </Link>
            </div>
          </div>
          <div className="mt-10 lg:mt-0 h-[500px] overflow-hidden rounded-lg">
            <img
              className="w-full h-full object-cover"
              src={image0}
              alt="Community support"
            />
          </div>
        </div>
      </div>
    </div>
  );
}