import { team } from '../data/content';

export default function Team() {
  return (
    <section id="about" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Notre Équipe</h2>
          <p className="mt-4 text-lg text-gray-500">
            Les membres fondateurs d'AFRIKLUMI, engagés pour un impact positif en Afrique.
          </p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-2">
          {team.map((member) => (
            <div key={member.id} className="bg-white rounded-lg shadow-lg p-6">
              <div className="flex items-center space-x-4">
                <img
                  className="h-16 w-16 rounded-full object-cover"
                  src={member.image}
                  alt={member.name}
                />
                <div>
                  <h3 className="text-xl font-semibold text-gray-900">{member.name}</h3>
                  <p className="text-green-600">{member.role}</p>
                </div>
              </div>
              <p className="mt-4 text-gray-600">{member.bio}</p>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <p className="text-lg text-gray-600">
            Notre équipe est dédiée à la création d'un impact durable à travers nos projets
            de développement communautaire et d'aide humanitaire.
          </p>
        </div>
      </div>
    </section>
  );
}