import React, { useState } from 'react';
import { Mail, MapPin, Phone } from 'lucide-react';
import emailjs from '@emailjs/browser';

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState({
    type: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatus({ type: '', message: '' });

    try {
      const result = await emailjs.send(
        'service_4e3zaej',
        'template_8tng09p',
        {
          from_name: formData.name,
          from_email: formData.email,
          message: formData.message,
          to_name: 'AFRIKLUMI',
          reply_to: formData.email,
        },
        'Wh_9fcV20EiLxwvMm'
      );

      if (result.text === 'OK') {
        setStatus({
          type: 'success',
          message: 'Votre message a été envoyé avec succès !'
        });
        setFormData({ name: '', email: '', message: '' });
      }
    } catch (error) {
      console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
      setStatus({
        type: 'error',
        message: 'Une erreur est survenue lors de l\'envoi du message. Veuillez réessayer.'
      });
    }

    setIsSubmitting(false);
  };

  return (
    <section id="contact" className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900">Contactez-nous</h2>
          <p className="mt-4 text-lg text-gray-500">
            Nous sommes à votre écoute pour toute question ou collaboration.
          </p>
        </div>

        <div className="mt-12 grid gap-8 lg:grid-cols-2">
          <div className="bg-gray-50 p-8 rounded-lg shadow-lg">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Nom complet
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                ></textarea>
              </div>
              {status.message && (
                <div className={`p-4 rounded-md ${
                  status.type === 'success' ? 'bg-green-50 text-green-800' : 'bg-red-50 text-red-800'
                }`}>
                  {status.message}
                </div>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className={`w-full bg-green-600 text-white px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200 ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
              >
                {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
              </button>
            </form>
          </div>

          <div className="space-y-8 bg-gray-50 p-8 rounded-lg shadow-lg">
            <div className="flex items-start space-x-4">
              <MapPin className="text-green-600 flex-shrink-0 h-6 w-6" />
              <div>
                <h3 className="text-lg font-medium text-gray-900">Notre adresse</h3>
                <p className="mt-1 text-gray-600">319 Route de la Chapelle, 76640 Cléville, France</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Phone className="text-green-600 flex-shrink-0 h-6 w-6" />
              <div>
                <h3 className="text-lg font-medium text-gray-900">Téléphone</h3>
                <p className="mt-1 text-gray-600">06 58 89 85 31</p>
              </div>
            </div>

            <div className="flex items-start space-x-4">
              <Mail className="text-green-600 flex-shrink-0 h-6 w-6" />
              <div>
                <h3 className="text-lg font-medium text-gray-900">Email</h3>
                <p className="mt-1 text-gray-600">afriklumi01@gmail.com</p>
              </div>
            </div>

            <div className="mt-8 pt-8 border-t border-gray-200">
              <h4 className="text-lg font-medium text-gray-900 mb-4">Nos horaires</h4>
              <div className="grid grid-cols-2 gap-4 text-gray-600">
                <div>
                  <p className="font-medium">Lundi - Vendredi</p>
                  <p>9h00 - 18h00</p>
                </div>
                <div>
                  <p className="font-medium">Samedi</p>
                  <p>9h00 - 12h00</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}